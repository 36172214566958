import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Switch,
  Link
} from "@material-ui/core";
import { FC } from "react";

import {
  EditButton,
  Show,
  ShowProps,
  SimpleShowLayout,
  Toolbar,
  TopToolbar
} from "react-admin";
import { DeployButton } from "./deploy";

export const ShowInstance: FC<ShowProps> = props => {
  return (
    <Show
      actions={<ShowActions user={props.permissions} />}
      title={<Title />}
      {...props}
    >
      <SimpleShowLayout>
        <Details />
        <Settings user={props?.permissions} />
        <Deployment />
      </SimpleShowLayout>
    </Show>
  );
};

const Title = ({ record }: any) => record && `${record.company.name}`;

const ShowActions = ({ user, data, basePath }: any) => {
  const record = data || {};
  return (
    <TopToolbar>
      {user?.role === "admin" && (
        <EditButton record={record} basePath={basePath} />
      )}
    </TopToolbar>
  );
};

const Details = (props: any) => {
  const record = props.record;

  if (!record) return null;

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <CardHeader title="Company" />
        <CardContent>
          <Grid container spacing={4}>
            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Name</Typography>
              <Typography variant={"body1"}>{record.company.name}</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>E-mail</Typography>
              <Typography variant={"body1"}>
                {" "}
                <Link href={`mailto:${record.company.website}`}>
                  {record.company.email}
                </Link>
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Contact</Typography>
              <Typography variant={"body1"}>
                <Link href={`tel:${record.company.contact}`}>
                  {record.company.contact}
                </Link>
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Country</Typography>
              <Typography variant={"body1"}>
                {record.company.country}
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Timezone</Typography>
              <Typography variant={"body1"}>
                {record.company.timezone}
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Website</Typography>
              <Typography variant={"body1"}>
                <Link href={record.company.website}>
                  {record.company.website}
                </Link>
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Location</Typography>
              <Typography variant={"body1"}>
                {record.company.location}
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch defaultChecked={record.company.active} disabled />
              <Typography variant={"caption"}>Active</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const Settings = ({ record, user }: any) => {
  if (!record || user.role !== "admin") return null;

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <CardHeader title="Settings" />
        <CardContent>
          <Grid container spacing={4}>
            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Mode</Typography>
              <Typography variant={"body1"}>{record.settings?.mode}</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Subdomain</Typography>
              <Typography variant={"body1"}>
                {record.settings?.subdomain}
              </Typography>
            </Grid>
            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Public Subdomain</Typography>
              <Typography variant={"body1"}>
                {record.settings?.publicSubdomain}
              </Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.financialManagement?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Financial Managementt</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={
                  record.settings?.financialManagement?.orderlessRequest
                }
                disabled
              />
              <Typography variant={"caption"}>Orderless Request</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.inventoryManagement?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Inventory Management</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch defaultChecked={record.settings?.sms?.enabled} disabled />
              <Typography variant={"caption"}>SMS</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.whatsapp?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Whatsapp</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.onlinePayments?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Online Payments</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.qualityControl?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Quality Control</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.smsFromGeneXpert?.enabled}
                disabled
              />
              <Typography variant={"caption"}>SMS From GeneXpert</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.geneXpertServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>GeneXpert Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.hl7Server?.enabled}
                disabled
              />
              <Typography variant={"caption"}>HL7 Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.architectServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Architect Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.erbaServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Erba Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.echoLumenServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Echo-Lumen Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.xl1000eServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>xl1000e Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.sysmexServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Sysmex Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.tcpServer?.enabled}
                disabled
              />
              <Typography variant={"caption"}>TCP Server</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.surveillance?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Surveillance</Typography>
            </Grid>

            <Grid xs={6} md={3} item>
              <Switch
                defaultChecked={record.settings?.billing?.enabled}
                disabled
              />
              <Typography variant={"caption"}>Billing</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const Deployment = (props: any) => {
  const record = props.record;
  // console.log( record);
  if (!record) return null;

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <CardHeader title="Deployment" />
        <CardContent>
        <Grid container spacing={4}>
            <Grid xs={8} md={6} item>
              <Typography variant={"caption"}>Deployment URL</Typography>
              <Typography variant={"body1"}>
                <Link target="_blank" href={record?.deploymentUrl}>
                  {record?.deploymentUrl}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid xs={6} md={3} item>
              <Typography variant={"caption"}>Version</Typography>
              <Typography variant={"body1"}>{record?.version}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Toolbar style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container spacing={1}>
            <Grid item>
              <DeployButton
                id={record._id}
              />
            </Grid>
        </Grid>
        </Toolbar>
      </Card>
    </>
  );
};
