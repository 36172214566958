import { DatePicker } from "@material-ui/pickers";
import { addField } from "react-admin";

export const DateInput = addField(({ input, label }) => {
  return (
    <DatePicker
      label={label}
      inputVariant="filled"
      views={["year", "month", "date"]}
      labelFunc={day => day!.format("Do MMMM yyyy")}
      disableFuture
      openTo="year"
      fullWidth
      value={input.value || new Date()}
      onChange={input.onChange}
    />
  );
});
