import { useState } from "react";
import QrReader from "react-qr-scanner";
import {
  Button,
  Typography,
  Box,
  Fab,
  Grid,
  Dialog,
  useMediaQuery
} from "@material-ui/core";
import { Close, FlipCameraAndroid, Camera } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { addField, Button as RaButton } from "react-admin";
import { useHistory } from "react-router-dom";
import { useBeeper } from "../hooks";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}));

export const OrderNumberReader = () => {
  const history = useHistory();
  const beep = useBeeper("/beep.mp3");
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleClose() {
    setDialogOpen(false);
  }

  function handleOpen() {
    setDialogOpen(true);
  }

  function handleScan(data: any) {
    if (typeof data.text !== "string") return;

    if (data.text.length !== 24) return;

    beep();
    handleClose();
    history.push(`/orders/${data.text}/show`);
  }

  return (
    <>
      <QRCodeReader
        open={dialogOpen}
        onClose={handleClose}
        onChange={handleScan}
      />

      <RaButton label={"Scan QR Code"} variant="contained" onClick={handleOpen}>
        <Camera />
      </RaButton>
    </>
  );
};

export const QrPassInput = addField(({ input = {}, label }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleClose() {
    setDialogOpen(false);
  }

  function handleOpen() {
    setDialogOpen(true);
  }

  function handleScan(data: any) {
    if (typeof data?.text !== "string") return;

    const [, , v] = data.text.split("|");
    const segments = v.split("-");

    if (segments.length !== 4) return;

    input.onChange(v);
    setDialogOpen(false);
  }

  return (
    <>
      <QRCodeReader
        open={dialogOpen}
        onClose={handleClose}
        onChange={handleScan}
      />
      <Typography variant="caption" style={{ marginBottom: 4 }}>
        {label}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        style={{ marginBottom: 16 }}
        onClick={handleOpen}
      >
        {!!input.value ? input.value : `Capture ${label}`}
      </Button>
    </>
  );
});

export const QRCodeReader = ({ open, onClose, onChange }: any) => {
  const classes = useStyles();
  const [facingMode, setFacingMode] = useState("environment");
  const isDesktop = useMediaQuery("(min-width:600px)");

  function handleError(error: Error) {
    alert(error.message);
  }

  function handleToggle() {
    setFacingMode(value => (value === "environment" ? "user" : "environment"));
  }

  function handleScan(data: { text: string }) {
    if (!data?.text) return;

    onChange(data.text);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.backdrop}
      style={{ backgroundColor: "#000" }}
      fullScreen
      disableBackdropClick={true}
    >
      {open ? (
        <>
          <QrReader
            delay={1000}
            style={{
              width: "100%",
              height: "100%"
            }}
            maxImageSize={1500}
            legacyMode={true}
            constraints={
              isDesktop
                ? undefined
                : {
                    video: {
                      facingMode: { exact: facingMode }
                    }
                  }
            }
            facingMode={facingMode}
            onError={handleError}
            onScan={handleScan}
          />
          <Box
            style={{ position: "absolute", bottom: 16, left: 16, right: 16 }}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <Fab color="primary" onClick={handleToggle}>
                  <FlipCameraAndroid />
                </Fab>
              </Grid>
              <Grid item>
                <Fab color="primary" onClick={onClose}>
                  <Close />
                </Fab>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
    </Dialog>
  );
};
