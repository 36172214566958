import { addField } from "ra-core";
import { Grid } from "@material-ui/core";
import { DateInput } from "react-admin";

const DateBetweenInput = ({ source }: { source: string }) => {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item>
        <DateInput
          variant="outlined"
          source={`${source}.after`}
          label={`From`}
        />
      </Grid>
      <Grid item>
        <DateInput
          variant="outlined"
          source={`${source}.before`}
          label={`To`}
        />
      </Grid>
    </Grid>
  );
};

DateBetweenInput.defaultProps = {
  addLabel: true,
  label: "createdAt"
};

export const DateRangeInput = addField(DateBetweenInput);
